import axios from "@/plugins/axios";
import { actionTypes, mutationTypes } from "@/store/Yield/types";
import { prepareBodyForInterval } from "@/helpers/data";
// import { showMessage } from "@/helpers/messages";

export const actions = {
    [actionTypes.getData]({ commit }, { period, aabSiteIds, timezone, noCommit }) {
        !noCommit && commit(mutationTypes.yieldStart);

        let data = prepareBodyForInterval(period, aabSiteIds, timezone);

        if (typeof data.period != "string") {
            let dateFrom = new Date(data.period.dateFrom);
            let dateFromYYYYMMDD = dateFrom.toISOString().split("T")[0];

            let dateTo = new Date(data.period.dateTo);
            let dateToYYYYMMDD = dateTo.toISOString().split("T")[0];

            data["period"] = "custom_range";
            data["date_from"] = dateFromYYYYMMDD;
            data["date_to"] = dateToYYYYMMDD;
        }

        return axios({
            method: "post",
            url: "yield",
            data: data,
        }).then(
            ({ data }) => {
                !noCommit && commit(mutationTypes.yieldSuccess, data);
                return data;
            },
            () => {
                !noCommit && commit(mutationTypes.yieldFailure);
            }
        );
    },
    // TODO: not ready yet!
    [actionTypes.getGrandTotal]({ commit }, { aabSiteIds, timezone }) {
        commit(mutationTypes.grandTotalStart);
        const jan2020 = new Date(2020, 1);
        const today = new Date();

        return axios({
            method: "post",
            url: "yield-total",
            data: { date_from: jan2020, date_to: today, aab_sites_ids: aabSiteIds, timezone: timezone },
        }).then(
            ({ data }) => {
                if (data?.metadata?.["total_revenue"]) {
                    commit(mutationTypes.grandTotalSuccess, data.metadata);
                } else {
                    commit(mutationTypes.grandTotalFailure);
                }
            },
            () => {
                commit(mutationTypes.grandTotalFailure);
            }
        );
    },

    [actionTypes.editRecord]({ commit }, payload) {
        commit(mutationTypes.recordEditDataStart);

        return axios({
            method: "put",
            url: "daily-data/" + payload.id,
            data: {
                publisher_revenue_custom: payload.publisher_revenue_custom,
                is_approved: payload.is_approved,
            },
        }).then(
            (data) => {
                commit(mutationTypes.recordEditDataSuccess, data.data);
                // showMessage(data.data, "edit", "daily-data");
                window.location.reload();
            },
            () => {
                commit(mutationTypes.recordEditDataFailure);
            }
        );
    },
};
