<template>
    <div>
        <!-- Graf pro Impressions -->
        <div ref="chartImpressions" style="width: 100%; height: 350px"></div>
    </div>
</template>

<script>
import { computed, onBeforeUnmount, onMounted, ref } from "vue";
import * as echarts from "echarts";
import { formatDate } from "@/helpers/filters";
import { getItem } from "@/store/persistantStorage";
import { numberFormatImpressions, numberFormatWithSpace } from "@/helpers/data";

export default {
    name: "RevenueBySlotsChart",
    props: {
        earningsData: {
            type: Array,
            required: true,
        },
    },
    setup(props) {
        const chartImpressions = ref(null);
        let impressionsChartInstance = null;

        const isLightTheme = computed(() => getItem("theme") === "light");

        // Získání unikátních názvů slotů
        const allSlotNames = computed(() => {
            const names = new Set();
            props.earningsData.forEach((item) => {
                item.slot_data.forEach((slot) => names.add(slot.slot_name));
            });
            return Array.from(names);
        });

        // Datum pro osu x
        const dates = computed(() => props.earningsData.map((item) => formatDate(new Date(item.date))));

        // Řady pro graf Impressions – každý slot se vykreslí jako segment v rámci stacku "impressionsStack"
        const impressionsSeries = computed(() => {
            return allSlotNames.value.map((slotName) => {
                return {
                    name: `${slotName}`,
                    type: "bar",
                    stack: "impressionsStack",
                    label: {
                        show: dates.value.length < 20,
                        formatter: numberFormatImpressions,
                    },
                    data: props.earningsData.map((item) => {
                        const slot = item.slot_data.find((s) => s.slot_name === slotName);
                        return slot ? slot.impressions : 0;
                    }),
                    itemStyle: {
                        // Možnost nastavení vlastní barvy
                    },
                };
            });
        });

        onMounted(() => {
            // Inicializace grafu pro Impressions
            impressionsChartInstance = echarts.init(chartImpressions.value);
            const impressionsOption = {
                tooltip: {
                    trigger: "axis",
                    axisPointer: { type: "shadow" },
                    formatter: function (params) {
                        let result = params[0].name + "<br/>";
                        // Spočítáme celkové impressions
                        const totalImpressions = params.reduce((sum, item) => {
                            return sum + (Number(item.value) || 0);
                        }, 0);
                        params.forEach(function (item) {
                            result +=
                                item.marker +
                                item.seriesName +
                                ": " +
                                numberFormatWithSpace(item.value.toString()) +
                                "<br/>";
                        });
                        result +=
                            "<strong>Total Impressions: " +
                            numberFormatWithSpace(totalImpressions.toString()) +
                            "</strong>";
                        return result;
                    },
                },
                legend: {
                    data: impressionsSeries.value.map((s) => s.name),
                    bottom: 0,
                    itemGap: 25,
                    textStyle: {
                        color: isLightTheme.value ? "#000000" : "#ffffff",
                        fontSize: 15,
                        fontFamily: "Open Sans",
                    },
                },
                xAxis: {
                    type: "category",
                    data: dates.value,
                    axisLabel: {
                        color: isLightTheme.value ? "#000000" : "#ffffff",
                    },
                },
                yAxis: {
                    type: "value",
                    name: "Impressions",
                    axisLine: {
                        lineStyle: { color: isLightTheme.value ? "#2596be" : "#4fa8ef" },
                    },
                    axisLabel: {
                        formatter: numberFormatImpressions,
                    },
                },
                series: impressionsSeries.value,
                grid: {
                    left: "1%",
                    right: "1%",
                    top: "10%",
                    bottom: "10%",
                    containLabel: true,
                },
            };
            impressionsChartInstance.setOption(impressionsOption);
        });

        onBeforeUnmount(() => {
            if (impressionsChartInstance) impressionsChartInstance.dispose();
        });

        return {
            chartImpressions,
        };
    },
};
</script>

<style scoped>
/* Stylování komponenty */
</style>
