<template>
    <div ref="chart" style="width: 100%; height: 500px"></div>
</template>
<script>
import * as echarts from "echarts";
import { ref, onMounted, computed, onBeforeUnmount } from "vue";
import { getItem } from "@/store/persistantStorage";
import { formatImpressions } from "@/helpers/graphs";
import { dateFormatAsText, numberFormatImpressions, numberFormatWithSpace } from "@/helpers/data";

export default {
    name: "WebsImpressionsStackedBarChart",
    props: {
        earningsCsvData: {
            type: Array,
            required: true,
        },
    },
    setup(props) {
        const chart = ref(null);
        let myChart = null;
        const isLightTheme = computed(() => getItem("theme") === "light");

        const getWebsites = computed(() => {
            return [...new Set(props.earningsCsvData.map((item) => item.website))].sort();
        });
        const getDates = computed(() => {
            const allDates = props.earningsCsvData.map((item) => item.date);
            return [...new Set(allDates)].sort((a, b) => new Date(a) - new Date(b));
        });
        const transformData = computed(() => {
            const websites = getWebsites.value;
            const dates = getDates.value;
            // Seskupíme data podle webu a vytvoříme mapu: datum => impressions
            const groupedData = websites.reduce((acc, website) => {
                acc[website] = props.earningsCsvData
                    .filter((item) => item.website === website)
                    .reduce((map, item) => {
                        map[item.date] = item.impressions;
                        return map;
                    }, {});
                return acc;
            }, {});
            // Pro každé datum z globálního seznamu dates vytvoříme hodnotu,
            // pokud web nemá záznam pro dané datum, použijeme 0
            const series = websites.map((website) => {
                const websiteDataMap = groupedData[website] || {};
                const seriesData = dates.map((date) => (websiteDataMap[date] !== undefined ? websiteDataMap[date] : 0));
                return {
                    name: website,
                    type: "bar",
                    stack: "Total",
                    data: seriesData,
                    itemStyle: {
                        borderWidth: 0,
                        borderColor: "#ffffff",
                    },
                    label: {
                        show: dates.length < 60,
                        position: "inside",
                        formatter: formatImpressions,
                        color: isLightTheme.value ? "#000000" : "#ffffff",
                    },
                };
            });
            return series.filter((serie) => serie.data.length > 0);
        });

        onMounted(() => {
            myChart = echarts.init(chart.value);
            const option = {
                title: {
                    text: "Impressions",
                    textStyle: {
                        color: isLightTheme.value ? "#000000" : "#ffffff",
                    },
                },
                tooltip: {
                    trigger: "axis",
                    formatter: function (params) {
                        let result = "<div>" + dateFormatAsText(params[0].name) + "</div>";
                        let totalValue = 0;
                        params.forEach((item) => {
                            totalValue += item.value;
                        });
                        result += `<div style="padding-bottom: 0.25em;">Total: ${numberFormatWithSpace(
                            totalValue
                        )}</div>`;
                        params.forEach((item) => {
                            result +=
                                item.marker + item.seriesName + ": " + numberFormatWithSpace(item.value) + "<br/>";
                        });
                        return result;
                    },
                },
                legend: {
                    data: getWebsites.value,
                    bottom: 0,
                    itemGap: 25,
                    textStyle: {
                        color: isLightTheme.value ? "#000000" : "#ffffff",
                        fontSize: 15,
                        fontFamily: "Open Sans",
                    },
                },
                grid: {
                    left: "1%",
                    right: "1%",
                    top: "10%",
                    bottom: "10%",
                    containLabel: true,
                },
                xAxis: {
                    type: "category",
                    boundaryGap: true,
                    data: getDates.value,
                    axisLabel: {
                        formatter: dateFormatAsText,
                        color: isLightTheme.value ? "#000000" : "#ffffff",
                    },
                },
                yAxis: {
                    type: "value",
                    axisLine: {
                        lineStyle: {
                            color: isLightTheme.value ? "#000000" : "#ffffff",
                        },
                    },
                    axisLabel: {
                        formatter: numberFormatImpressions,
                        color: isLightTheme.value ? "#000000" : "#ffffff",
                    },
                },
                series: transformData.value,
            };
            myChart.setOption(option);
        });
        onBeforeUnmount(() => {
            if (myChart) {
                myChart.dispose();
            }
        });
        return {
            chart,
        };
    },
};
</script>
<style scoped></style>
