<template>
    <div ref="chart" style="width: 100%; height: 500px"></div>
</template>

<script>
import * as echarts from "echarts";
import { ref, onMounted, computed, onBeforeUnmount } from "vue";
import store from "@/store/store";
import { getterTypes as publisherGetterTypes } from "@/store/Publisher/types";
import { dateFormatAsText, getCurrencySymbol, getExchangeRateFromCzk } from "@/helpers/data";
import { getItem } from "@/store/persistantStorage";
import { formatRevenue } from "@/helpers/graphs";

export default {
    name: "WebsRevenueStackedLineChart",
    props: {
        earningsCsvData: {
            type: Array,
            required: true,
        },
    },
    setup(props) {
        // Čtení currency, exchange rate a symbolu z úložiště/storu
        const selectCurrency = computed(() => store.getters[publisherGetterTypes.getSelectedPublisherCurrency]);
        const currencySymbol = computed(() => getCurrencySymbol(selectCurrency.value));
        const exchangeRate = computed(() => getExchangeRateFromCzk(selectCurrency.value));

        const chart = ref(null);
        let myChart = null;
        const isLightTheme = computed(() => getItem("theme") == "light");

        // Získáme seznam unikátních webů a datumů
        const getWebsites = computed(() => {
            return [...new Set(props.earningsCsvData.map((item) => item.website))].sort();
        });
        const getDates = computed(() => {
            const allDates = props.earningsCsvData.map((item) => item.date);
            return [...new Set(allDates)].sort((a, b) => new Date(a) - new Date(b));
        });

        // Transformujeme data tak, aby každá série (web) měla hodnotu pro každý den z globálního seznamu.
        // Pokud pro dané datum není záznam, vrátí se hodnota 0.
        const transformData = computed(() => {
            const websites = getWebsites.value;
            const dates = getDates.value;

            // Vytvoříme mapu, kde pro každý web budou mít datum => revenue
            const groupedData = websites.reduce((acc, website) => {
                acc[website] = {};
                props.earningsCsvData
                    .filter((item) => item.website === website)
                    .forEach((item) => {
                        // Výpočet revenue s použitím exchangeRate
                        const revenue = Math.round((item["publisher revenue"] / exchangeRate.value) * 100) / 100;
                        acc[website][item.date] = revenue;
                    });
                return acc;
            }, {});

            // Vytvoříme sérii pro každý web, kde pro každé datum vložíme hodnotu nebo 0
            const series = websites.map((website) => {
                const websiteDataMap = groupedData[website] || {};
                const seriesData = dates.map((date) => (websiteDataMap[date] !== undefined ? websiteDataMap[date] : 0));
                return {
                    name: website,
                    type: "bar", // stejný typ jako v WebsImpressionsStackedBarChart
                    stack: "Total",
                    data: seriesData,
                    itemStyle: {
                        borderWidth: 0,
                        borderColor: "#ffffff",
                    },
                    label: {
                        show: dates.length < 60,
                        position: "inside",
                        formatter: (params) => formatRevenue(params, currencySymbol.value),
                        color: isLightTheme.value ? "#000000" : "#ffffff",
                    },
                };
            });
            return series.filter((serie) => serie.data.length > 0);
        });

        onMounted(() => {
            myChart = echarts.init(chart.value);
            const option = {
                title: {
                    text: "Revenue",
                    textStyle: {
                        color: isLightTheme.value ? "#000000" : "#ffffff",
                    },
                },
                tooltip: {
                    trigger: "axis",
                    formatter: function (params) {
                        let result = "<strong>" + dateFormatAsText(params[0].name) + "</strong><br/>";
                        let totalValue = 0;
                        params.forEach(function (item) {
                            totalValue += item.value;
                        });
                        result += `<div style="padding-bottom: 0.25em;">Total: ${parseFloat(totalValue).toFixed(2)} ${
                            currencySymbol.value
                        }</div>`;
                        params.forEach(function (item) {
                            result +=
                                item.marker +
                                item.seriesName +
                                ": " +
                                parseFloat(item.value).toFixed(2) +
                                " " +
                                currencySymbol.value +
                                "<br/>";
                        });
                        return result;
                    },
                },
                legend: {
                    data: getWebsites.value,
                    bottom: 0,
                    itemGap: 25,
                    textStyle: {
                        color: isLightTheme.value ? "#000000" : "#ffffff",
                        fontSize: 15,
                        fontFamily: "Open Sans",
                    },
                },
                grid: {
                    left: "1%",
                    right: "1%",
                    top: "10%",
                    bottom: "10%",
                    containLabel: true,
                },
                xAxis: {
                    type: "category",
                    boundaryGap: true,
                    data: getDates.value,
                    axisLabel: {
                        formatter: dateFormatAsText,
                        color: isLightTheme.value ? "#000000" : "#ffffff",
                    },
                },
                yAxis: {
                    type: "value",
                    axisLine: {
                        lineStyle: {
                            color: isLightTheme.value ? "#000000" : "#ffffff",
                        },
                    },
                    axisLabel: {
                        formatter: function (value) {
                            return value + " " + currencySymbol.value;
                        },
                        color: isLightTheme.value ? "#000000" : "#ffffff",
                    },
                },
                series: transformData.value,
            };
            myChart.setOption(option);
        });

        onBeforeUnmount(() => {
            if (myChart) {
                myChart.dispose();
            }
        });

        return { chart };
    },
};
</script>

<style scoped></style>
