<template>
    <div>
        <!-- Graf pro Revenue -->
        <div ref="chartRevenue" style="width: 100%; height: 350px; margin-bottom: 20px"></div>
    </div>
</template>

<script>
import { computed, onBeforeUnmount, onMounted, ref } from "vue";
import * as echarts from "echarts";
import { formatDate } from "@/helpers/filters";
import store from "@/store/store";
import { getItem } from "@/store/persistantStorage";
import { getterTypes as publisherGetterTypes } from "@/store/Publisher/types";
import { getCurrencySymbol, getExchangeRateFromCzk } from "@/helpers/data";
import { formatRevenue } from "@/helpers/graphs";

export default {
    name: "RevenueBySlotsChart",
    props: {
        earningsData: {
            type: Array,
            required: true,
        },
    },
    setup(props) {
        const chartRevenue = ref(null);
        let revenueChartInstance = null;

        const isLightTheme = computed(() => getItem("theme") === "light");

        // Získání unikátních názvů slotů
        const allSlotNames = computed(() => {
            const names = new Set();
            props.earningsData.forEach((item) => {
                item.slot_data.forEach((slot) => names.add(slot.slot_name));
            });
            return Array.from(names);
        });

        // Datum pro osu x
        const dates = computed(() => props.earningsData.map((item) => formatDate(new Date(item.date))));

        const selectCurrency = computed(() => store.getters[publisherGetterTypes.getSelectedPublisherCurrency]);
        const exchangeRate = computed(() => getExchangeRateFromCzk(selectCurrency.value));
        const currencySymbol = computed(() => getCurrencySymbol(selectCurrency.value));

        // Definice pole barev, například:
        const slotColorsLight = ["#1fad82", "#3498db", "#e67e22", "#9b59b6", "#f1c40f"];
        const slotColorsDark = ["#a8ef4f", "#1fad82", "#fff100", "#ff8800", "#e67e22"];

        // V computed revenueSeries přiřaďte barvu podle indexu
        const revenueSeries = computed(() => {
            // Vybereme správný seznam barev dle tématu
            const colors = isLightTheme.value ? slotColorsLight : slotColorsDark;
            return allSlotNames.value.map((slotName, index) => {
                return {
                    name: `${slotName}`,
                    type: "bar",
                    stack: "revenueStack",
                    label: {
                        show: dates.value.length < 20,
                        formatter: (params) => formatRevenue(params, currencySymbol.value),
                    },
                    data: props.earningsData.map((item) => {
                        const slot = item.slot_data.find((s) => s.slot_name === slotName);
                        if (!slot || typeof slot.publisher_revenue === "undefined") {
                            return 0;
                        }
                        let value = Math.round((slot.publisher_revenue / exchangeRate.value) * 100) / 100;
                        return value;
                    }),
                    // Přiřazení barvy z pole, pokud index přesáhne délku pole, použije se cyklicky
                    itemStyle: {
                        color: colors[index % colors.length],
                    },
                };
            });
        });

        onMounted(() => {
            // Inicializace grafu pro Revenue
            revenueChartInstance = echarts.init(chartRevenue.value);
            const revenueOption = {
                tooltip: {
                    trigger: "axis",
                    axisPointer: { type: "shadow" },
                    formatter: function (params) {
                        let result = params[0].name + "<br/>";
                        const totalRevenue = params.reduce((sum, item) => sum + (Number(item.value) || 0), 0);
                        params.forEach((item) => {
                            result +=
                                item.marker +
                                item.seriesName +
                                ": " +
                                item.value +
                                " " +
                                currencySymbol.value +
                                "<br/>";
                        });
                        result +=
                            "<strong>Total Revenue: " +
                            totalRevenue.toFixed(2) +
                            " " +
                            currencySymbol.value +
                            "</strong>";
                        return result;
                    },
                },
                legend: {
                    data: revenueSeries.value.map((s) => s.name),
                    bottom: 0,
                    itemGap: 25,
                    textStyle: {
                        color: isLightTheme.value ? "#000000" : "#ffffff",
                        fontSize: 15,
                        fontFamily: "Open Sans",
                    },
                },
                xAxis: {
                    type: "category",
                    data: dates.value,
                    axisLabel: {
                        color: isLightTheme.value ? "#000000" : "#ffffff",
                    },
                },
                yAxis: {
                    type: "value",
                    name: "Revenue",
                    axisLine: {
                        lineStyle: { color: isLightTheme.value ? "#1fad82" : "#a8ef4f" },
                    },
                    axisLabel: {
                        formatter: "{value} " + currencySymbol.value,
                    },
                },
                series: revenueSeries.value,
                grid: {
                    left: "1%",
                    right: "1%",
                    top: "10%",
                    bottom: "10%",
                    containLabel: true,
                },
            };
            revenueChartInstance.setOption(revenueOption);
        });

        onBeforeUnmount(() => {
            if (revenueChartInstance) revenueChartInstance.dispose();
        });

        return {
            chartRevenue,
        };
    },
};
</script>

<style scoped>
/* Stylování komponenty */
</style>
