<template>
    <BaseCard>
        <template #header>
            <LaCsvDownload :csv-data="domains.length > 1 ? yieldCsvData : yieldData" :filename="filename" />
        </template>
        <template v-if="!yieldLoading">
            <div class="columns">
                <div class="column">
                    <RevenueImpressionsChart
                        :earnings-data="yieldData"
                        v-if="domains.length == 1 && yieldMetadata['use_slot_data'] == false"
                    />

                    <template v-if="domains.length == 1 && yieldMetadata['use_slot_data'] == true">
                        <RevenueBySlotsChart :earnings-data="yieldData" />
                        <impressionsBySlotsChart :earnings-data="yieldData" />
                    </template>

                    <WebsRevenueStackedBarChart
                        v-if="domains.length > 1 && yieldData.length"
                        :earnings-csv-data="yieldData"
                    />
                    <WebsImpressionsStackedBarChart
                        v-if="domains.length > 1 && yieldData.length"
                        :earnings-csv-data="yieldData"
                    />
                </div>
            </div>

            <div style="margin-top: 2rem">
                <LaYieldTable :data="domains.length > 1 ? yieldCsvData : yieldData" :domains-length="domains.length" />
            </div>

            <LaDialog name="dailyDataForm">
                <LaDailyDataForm />
            </LaDialog>
        </template>
        <LaContentLoader v-else />
    </BaseCard>
</template>

<script>
import BaseCard from "@/components/BaseCard";
import LaContentLoader from "@/components/loaders/ContentLoader";
import LaYieldTable from "@/components/yield/YieldTable";
import RevenueImpressionsChart from "@/components/charts/RevenueImpressionsChart.vue";
import RevenueBySlotsChart from "@/components/charts/RevenueBySlotsChart.vue";
import ImpressionsBySlotsChart from "@/components/charts/ImpressionsBySlotsChart.vue";
// import CpmBySlotsChart from "@/components/charts/CpmBySlotsChart.vue";
import WebsRevenueStackedBarChart from "@/components/charts/WebsRevenueStackedBarChart.vue";
import WebsImpressionsStackedBarChart from "@/components/charts/WebsImpressionsStackedBarChart.vue";
import LaDailyDataForm from "@/components/dialog/DailyDataForm.vue";
import LaDialog from "@/components/dialog/Dialog.vue";
import LaCsvDownload from "@/components/CsvDownload.vue";

export default {
    name: "LaYieldDataCard",
    components: {
        LaCsvDownload,
        BaseCard,
        LaContentLoader,
        LaYieldTable,
        RevenueImpressionsChart,
        WebsRevenueStackedBarChart,
        WebsImpressionsStackedBarChart,
        LaDialog,
        LaDailyDataForm,
        RevenueBySlotsChart,
        ImpressionsBySlotsChart,
        // CpmBySlotsChart,
    },
    props: {
        yieldLoading: {
            type: Boolean,
        },
        useSlotData: {
            type: Boolean,
            required: true,
            default: false,
        },
        yieldMetadata: {
            type: Object,
            required: true,
        },
        yieldData: {
            type: Array,
            required: true,
        },
        yieldCsvData: {
            type: Array,
            required: true,
        },
        domains: {
            type: Array,
            required: true,
        },
    },
    computed: {
        filename() {
            return `${this.domains.join("_")}_yield_%from>%to`;
        },
    },
};
</script>
