<template>
    <section>
        <div class="container">
            <div class="logo-container">
                <img src="@/assets/login/img/Logo.svg" alt="Logo" />
            </div>

            <div class="form-container">
                <form :class="hasErrors ? 'error' : ''" data-test="base-submit">
                    <h2>Dashboard Login</h2>
                    <div class="input-container">
                        <label for="email">Your email</label>
                        <input
                            v-model="emailValue"
                            type="email"
                            placeholder="Enter your email"
                            id="email"
                            @input="dataModify"
                            data-test="base-input"
                        />
                    </div>
                    <div class="input-container">
                        <label for="password">Password</label>
                        <div class="password-container">
                            <input
                                v-model="passwordValue"
                                :type="inputPasswordType"
                                placeholder="Enter your password"
                                id="password"
                                @input="dataModify"
                                data-test="base-input"
                            />
                            <div
                                id="togglePassword"
                                :class="showPassword ? 'show-password' : 'hide-password'"
                                @click="togglePassword"
                            />
                        </div>
                    </div>

                    <button type="button" @click="send" data-test="base-button">
                        {{ hasErrors ? "Wrong email or password" : "Enter the dashboard" }}
                    </button>
                </form>
                <img id="bg-circle" src="@/assets/login/img/Bg_circle.svg" loading="lazy" alt="Bg-circle" />

                <router-link :to="{ name: 'passwordRecovery' }" data-test="password-recovery"
                    >Reset my password</router-link
                >
            </div>
        </div>
    </section>
</template>

<script>
import { actionTypes as loggedUserActionTypes, getterTypes } from "@/store/LoggedUser/types";
import { computed, ref, watch } from "vue";
import store from "@/store/store";
import { cmtScriptContent } from "@/helpers/cmt";
import { useRoute } from "vue-router";

export default {
    name: "Login",
    data() {
        return {
            emailValue: "",
            passwordValue: "",
            inputPasswordType: "password",
            showPassword: false,
            showError: false,
            isPending: true,
        };
    },

    setup() {
        const route = useRoute();

        let redirect = "";
        if (route && route.query && route.query.redirect) {
            redirect = route.query.redirect;
        }

        const loginAction = loggedUserActionTypes.login;
        const errors = computed(() => store.getters[getterTypes.getLoginFormErrors] || {});
        const hasErrors = ref(false);

        watch(errors, (newErrors) => {
            hasErrors.value = Object.keys(newErrors).length > 0;
        });

        return {
            loginAction,
            errors,
            hasErrors,
            redirect,
        };
    },
    created() {
        let tag = document.createElement("script");
        tag.type = "text/javascript";
        tag.async = true;
        tag.appendChild(document.createTextNode(cmtScriptContent));
        document.head.appendChild(tag);
    },
    methods: {
        togglePassword() {
            this.showPassword = !this.showPassword;
            if (this.inputPasswordType === "password") {
                this.inputPasswordType = "text";
            } else {
                this.inputPasswordType = "password";
            }
        },
        send() {
            const payload = {
                email: this.emailValue,
                password: this.passwordValue,
            };
            store.dispatch(this.loginAction, payload).finally(() => {
                this.isPending = false;
            });
        },
        dataModify() {
            this.hasErrors = false;
        },
    },
};
</script>

<style scoped lang="scss"></style>
