<template>
    <switch-theme-message />

    <LaPageHeader heading="Dashboard" data-cy="select-website">
        <template #controls v-if="websites?.length">
            <LaWebsiteDatePickers
                v-model="websiteDatesModel"
                :websites="websites"
                :tz="tz"
                :multiple="true"
                :select-all-defaults="true"
            />
        </template>
    </LaPageHeader>

    <portal-target name="dashboardTop" v-if="websites?.length" />

    <LaNoData
        v-if="websites && !selectedWebsites?.length"
        header="Add website"
        description="Please add website to show block rate data for it."
    >
        <LaAddWebsite />
    </LaNoData>
    <template
        v-if="
            !publisher?.is_yield &&
            websites &&
            selectedWebsites.length &&
            selectedWebsites.length > 0 &&
            selectedWebsites.length < 100
        "
    >
        <LaEarningsCards v-if="monetizing" :payload="filterData" :websites="selectedWebsites" />
        <LaBlockRateCards v-else :payload="filterData" :websites="selectedWebsites" />
    </template>
    <template
        v-if="
            publisher?.is_yield &&
            websites &&
            selectedWebsites.length &&
            selectedWebsites.length > 0 &&
            selectedWebsites.length < 100
        "
    >
        <LaYieldCards :payload="filterData" :websites="selectedWebsites" />
    </template>
</template>

<script>
import LaPageHeader from "@/components/PageHeader";
import store from "@/store/store";
import LaNoData from "@/components/NoData";
import LaWebsiteDatePickers from "@/components/filters/WebsiteDatePickers";
import LaEarningsCards from "@/components/earnings/EarningsCards";
import LaYieldCards from "@/components/yield/YieldCards";
import LaBlockRateCards from "@/components/dashboard/BlockRateCards";
import {
    getterTypes as websiteDatesGetterTypes,
    actionTypes as websiteDatesActionTypes,
} from "@/store/WebsiteDates/types";
import { getterTypes as websiteGetterTypes, actionTypes as websiteActionTypes } from "@/store/Websites/types";
import { getterTypes as userGetterTypes } from "@/store/LoggedUser/types";
import { computed, watch } from "vue";
import { getValidAabSiteIds, isWebsiteMeasured, isWebsiteMonetized } from "@/helpers/data";
import LaAddWebsite from "@/components/button/AddWebsite";
import SwitchThemeMessage from "@/components/SwitchThemeMessage.vue";
import { getterTypes } from "@/store/Publisher/types";

export default {
    name: "LaDashboard",
    components: {
        SwitchThemeMessage,
        LaPageHeader,
        LaAddWebsite,
        LaBlockRateCards,
        LaWebsiteDatePickers,
        LaNoData,
        //LaContentLoader,
        LaEarningsCards,
        LaYieldCards,
    },
    setup() {
        const loading = computed(() => !filterData.value || store.getters[websiteGetterTypes.isLoading]);
        const aabSiteIds = computed(() => store.getters[websiteDatesGetterTypes.getAabSiteIds]);
        const period = computed(() => store.getters[websiteDatesGetterTypes.getPeriod]);
        const userId = computed(() => store.getters[userGetterTypes.getUserId]);
        const tz = computed(() => store.getters[userGetterTypes.getTimezone]);
        const publisher = computed(() => store.getters[getterTypes.getPublisher]);

        const selectedPublisher = computed(() => {
            let pub = store.getters[getterTypes.getSelectedPublisher];
            console.log("selectedPublisher", pub);
            return pub;
        });
        if (!selectedPublisher.value) {
            setTimeout(() => {
                const currentUrl = window.location.href;
                window.location.href = currentUrl;
            }, 500);
        }

        const websites = computed(
            () =>
                store.getters[websiteGetterTypes.getWebsites]?.map((web) => ({
                    ...web,
                    disabled: !isWebsiteMeasured(web),
                })) || null
        );

        const selectedWebsites = computed(() =>
            websites.value?.filter(({ aab_site_id }) => aabSiteIds.value?.includes(aab_site_id))
        );
        const monetizing = computed(() => selectedWebsites.value?.some(isWebsiteMonetized));

        const onChangeWebsiteDates = (val) => {
            if (!val.period || !val.websites || !userId.value) {
                return;
            }

            store.dispatch(websiteDatesActionTypes.setWebsiteDatesToLS, {
                aabSiteIds: getValidAabSiteIds(val.websites),
                period: val.period,
                userId: userId.value,
            });
        };

        const filterData = computed(() => ({
            period: period.value,
            aabSiteIds: aabSiteIds.value,
            timezone: tz.value,
        }));

        const websiteDatesModel = computed({
            get: () => ({
                websites: selectedWebsites.value,
                period: period.value,
            }),
            set: onChangeWebsiteDates,
        });

        watch(
            [websites, userId],
            () => {
                if (!websites.value || !userId.value) {
                    !websites.value && store.dispatch(websiteActionTypes.getData);
                    return;
                }
                if (!period.value || !aabSiteIds.value) {
                    store.dispatch(websiteDatesActionTypes.getWebsiteDatesfromLS, {
                        websites: websites.value,
                        userId: userId.value,
                    });
                }
            },
            { immediate: true }
        );

        watch(
            [publisher],
            () => {
                console.log("publisher", publisher.value);
            },
            { immediate: true }
        );

        return {
            loading,
            websites,
            websiteDatesModel,
            monetizing,
            filterData,
            selectedWebsites,
            publisher,
            tz,
        };
    },
};
</script>
