<template>
    <LaBaseButton
        v-if="filename"
        text="Download Report"
        :disabled="!csv"
        buttonType="neutral"
        @click="download($event)"
        icon="download"
    />
</template>

<script>
import LaBaseButton from "@/components/button/BaseButton";
import { ref, computed } from "vue";
import { getExchangeRateFromCzk } from "@/helpers/data";
import store from "@/store/store";
import { getterTypes as publisherGetterTypes } from "@/store/Publisher/types";

export default {
    name: "LaCsvDownload",
    components: {
        LaBaseButton,
    },
    props: {
        csvData: {
            type: Array,
            required: true,
        },
        filename: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        const date = ref({ from: "", to: "" });
        const renderFilename = computed(
            () => props.filename.replace("%from", date.value.from).replace("%to", date.value.to) + ".csv"
        );

        const prepareCSV = (csv) => {
            const selectCurrency = computed(() => store.getters[publisherGetterTypes.getSelectedPublisherCurrency]);
            const exchangeRate = computed(() => getExchangeRateFromCzk(selectCurrency.value));
            if (csv) {
                let finalCsv = "\ufeff";
                // Odebrání sloupců id, web_id a slot_data
                let properties = Object.keys(csv[0]).filter(
                    (prop) => prop !== "id" && prop !== "web_id" && prop !== "slot_data"
                );
                const website_index = properties.indexOf("website");
                if (website_index > -1) {
                    properties.splice(website_index, 1);
                    properties.unshift("website");
                }
                const names = properties.join(",");
                finalCsv += names + "\n";

                csv.forEach((item) => {
                    // Vytvoření kopie objektu, aby nedošlo ke změně Vuex state
                    const row = { ...item };
                    let parts = [];
                    properties.forEach((name) => {
                        let value = row[name];
                        if (name.includes("revenue")) {
                            value = Math.round((value / exchangeRate.value) * 100) / 100;
                        }
                        if (name === "CPT") {
                            value = Math.round((value / exchangeRate.value) * 100) / 100;
                        }
                        if (name === "rpm") {
                            value = value / exchangeRate.value;
                            value = value.toFixed(3);
                        }
                        parts.push(value);
                    });
                    finalCsv += parts.join(",") + "\n";
                });
                if (properties.includes("date")) {
                    date.value.from = csv[0]["date"].replace(/\//g, ".").split("T")[0];
                    date.value.to = csv[csv.length - 1]["date"].replace(/\//g, ".").split("T")[0];
                }
                if (properties.includes("publisher revenue") && !properties.includes("publisher_revenue_custom")) {
                    let lines = finalCsv.split("\n");
                    let headers = lines[0].split(",");
                    headers = headers.map((header) => (header === "publisher revenue" ? "revenue" : header));
                    lines[0] = headers.join(",");
                    finalCsv = lines.join("\n");
                }
                return finalCsv;
            }
        };

        const csv = prepareCSV(props.csvData);
        return {
            renderFilename,
            csv,
            prepareCSV,
        };
    },
    methods: {
        download() {
            const csv = this.prepareCSV(this.csvData);
            const link = document.createElement("a");
            link.setAttribute("href", "data:text/csv;charset=UTF-8," + csv);
            link.setAttribute("download", `${this.renderFilename}`);
            link.click();
        },
    },
};
</script>

<style scoped></style>
